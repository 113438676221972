import React from "react";
import "../scss/mediaWithText.scss"
import Img from "gatsby-image"

export default class mediaWithText extends React.Component {
    getImage(url) {
        const img = this.props.queryImg.nodes.find(e =>{
            if(e.childImageSharp!== null && e.absolutePath.includes(url)) {
                return true
            } else {
                return null
            }
        })

        if(img) {
            return img.childImageSharp.fluid
        } 
        return img;
    }
    componentDidMount(){
        document.querySelector(".mediaWithText video").setAttribute("muted",true)
    }
    render() {
        const {image, text, title, colorScheme, id} = this.props
        
        return (
        <div className={"id-"+id + " mediaWithText " + (colorScheme.classes?colorScheme.classes:"")}>
            {
                image && image.filemime && image.filemime.includes('image') && this.getImage(image.uri) !== null ? (     
                    <Img className='image' fluid={this.getImage(image.uri)} alt="background" />
                ): 
                image && image.filemime && image.filemime.includes('image') && this.getImage(image.uri) ?
                    <img src={image.uri} alt="background" />
                :null
            }
            {
                 image && image.filemime && image.filemime.includes('video') ? (
                    <video playsInline autoPlay loop muted>
                        <source src={require("../images/downloaded" + image.uri)} type={image.filemime} />
                        Your browser does not support the video tag.
                    </video>
                ): ""
            }
            {
                text || title? (
                    <div className="text">
                        {title? <p className="title" dangerouslySetInnerHTML={{__html:title}} />: ""}
                        {text? <div className="body" dangerouslySetInnerHTML={{__html:text}} />: ""}
                    </div>
                ): ""
            }
        </div>)
    }
}