
let uuid=process.env['UUID']
if(typeof uuid === "undefined") {    
    uuid = "b0b62bdd-a70f-496c-8df2-e1441d9636b8";
}

exports.config = {
    host: "http://ssb.dig.brainsum.com/",
    siteUrl: "http://ssb-fe.dig.brainsum.com",
    mediafolder: "./src/images/downloaded/",
    uuid: uuid,
    endPoints: [
        {
            url: 'http://ssb.dig.brainsum.com/api/landing_page/' + uuid + '/?include=subpages,sections,sections.colorScheme,sections.layout,sections.image,sections.background,sections.images&jsonapi_include=1',
            saveName: "api.json"
        }
    ],
    mode: "prod",
    auth: {
        username: "powerfoyle",
        password: "<6dvJByxg-UG6G<K"
    }
}
