import React from "react";
import "../scss/twoMedia.scss"
import Img from "gatsby-image"

class twoMedia extends React.Component {

    getImage(url) {
        const img = this.props.queryImg.nodes.find(e =>{
            if(e.childImageSharp!== null && e.absolutePath.includes(url)) {
                return true
            } else {
                return null
            }
        })

        if(img) {
            return img.childImageSharp.fluid
        } 
        return img;
    }
    
    render() {
        let images = []
        this.props.images.map((e, i) => {
            images.push(
                <div className="image-wrapper" key={i}>
                    <Img objectFit="contain" className='image' fluid={this.getImage(e.uri)} alt="background" />
                </div>
            );
            return e;
        })
        return <div className="twoMedia">
            {images}
        </div>
    }
}

export default twoMedia;