import React, { Component } from "react"
import axios from "axios"

import MediaWithText from "../components/mediaWithText";
import TwoMedia from "../components/twoMedia";
import Header from "../components/header";
import Footer from "../components/footer";
import Menu from "../components/menu";
import { config } from "../../config"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import "../scss/index.scss"

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuStatus: false
    }
    this.handleMenuClick = this.handleMenuClick.bind(this)
  }

  handleMenuClick() {
    this.setState({
      menuStatus: !this.state.menuStatus
    })
  }

  async componentDidMount() {    
    let data;
    if(config.mode === "dev") {
      data = (await axios.get(config.endPoints[0].url, {
        auth: config.auth
    })).data.data
    } else {
      data = require("../data/api.json").data   
    }
    
    let sections = [];    
    data.sections.map((s, i) =>{      
      if(["paragraph--header","paragraph--simple", "paragraph--image_text"].includes(s.type)) {
        if(s.type==="paragraph--header") {
          s.image=s.background
        }
        if(s.anchor === "footer") {
          sections.push(<Footer key={i} />)
        } else {
          sections.push(<MediaWithText {...s} key={i} queryImg={this.props.data.images} />)
        }
      }

      if(["paragraph--images"].includes(s.type)) {
        sections.push(<TwoMedia {...s} key={i} queryImg={this.props.data.images} />)
      }
      return s;
    })

    this.setState({
      sections: data.sections,
      menu: data.menuItems,
      sectionsHtml: sections
    })
  }
  

  render() {
      return <div className="wrapper">
        <Header handleMenuClick={this.handleMenuClick} menuStatus={this.state.menuStatus}/>
        <Menu menu={this.state.menu} menuStatus={this.state.menuStatus} handleMenuClick={this.handleMenuClick}/>
        <div id="main"/>
        {this.state.sectionsHtml}
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>PowerFoyle</title>
          <meta name="description" content="PowerFoyle | Created By Brainsum"></meta>
        </Helmet>
      </div>
  }
}

export default index



export const mediaWithTextQuery = graphql`
  {
  images: allFile {
    nodes {
      childImageSharp {
        fluid(quality: 100, pngQuality: 100, pngCompressionSpeed: 100, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
      absolutePath
    }
  }
}
`